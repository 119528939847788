.section-divider {
  border-top: 2px solid #cccccc; /* Etwas dickerer, hellgrauer Strich */
  margin: 50px 0; /* Gleicher Abstand oben und unten */
}

/* Haupt-Content-Bereich */
.content {
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  margin-top: 120px;
  padding-top: 0;
  margin-left: auto; /* Zentriert den Content */
  margin-right: auto; /* Zentriert den Content */
}

/* Wide screen (e.g., 1600px and above) */
@media (min-width: 2000px) {
  .content {
    max-width: 40%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .dienstleistungen-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .dienstleistungen-text {
      flex: 1;
  }

  .logo-container {
      flex: 0 0 300px; /* Adjust width as necessary */
      display: flex;
      justify-content: flex-end;
  }

  .company-logo {
      max-width: 100%;
      height: auto;
  }
}

/* Normal PC screen (e.g., 1024px - 1999px) */
@media (min-width: 1024px) and (max-width: 1999px) {
  .content {
    max-width: 80%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .dienstleistungen-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .dienstleistungen-text {
      flex: 1;
  }

  .logo-container {
      flex: 0 0 300px; /* Adjust width as necessary */
      display: flex;
      justify-content: flex-end;
  }

  .company-logo {
      max-width: 100%;
      height: auto;
  }
}

/* Mobile screen (e.g., up to 1023px) */
@media (max-width: 1023px) {
  .content {
    max-width: 100%;
    margin-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  .section-divider {
    display: none;
  }

  .dienstleistungen-section {
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center-align the elements */
    text-align: center;
  }

  .logo-container {
      flex: 0 0 auto; /* Allow the container to resize based on its content */
      margin-top: 20px; /* Add some space above the logo */
      justify-content: center; /* Center the logo horizontally */
      width: 100%; /* Ensure the container takes full width */
  }

  .company-logo {
      max-width: 90%; /* Adjust the width for the logo to be smaller on mobile */
      height: auto;
  }
}

.content h2 {
  font-size: 2rem;
  color: black;
  border-bottom: 3px solid #ff6f61;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.content h3 {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 20px;
}

.content p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}


.content-inner {
  padding: 20px 0; /* Entfernung des seitlichen Paddings */
}


.content-image {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover; 
  object-position: center; 
  transition: opacity 0.5s ease-in-out; 
}

@media (max-width: 1023px) {
  .content{
    margin-top: 80px;
  }
  
  .content-image {
    margin-top: 27px;
    height: 250px;
    border-radius: 3px;
  }

  .content h2 {
    font-size: 1.5rem;
  }

  .content h3 {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .content p {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
}

/* About Section */
.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.about-me h2 {
  font-size: 2rem;
  color: black;
  border-bottom: 3px solid #ff6f61;
  padding-bottom: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.about-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.about-image {
  flex: 0 0 300px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
}

.about-image img {
  width: 100%;
  border-radius: 8px;
  align-self: flex-start;
}

.about-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.about-info .about-highlight {
  font-size: 1rem;
  color: white;
  background-color: #43a7ff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: bold;
}

.about-info p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

.linkedin-link{

}


.linkedin-link {
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
}

.linkedin-link:hover {
  text-decoration: underline;
}

/* Mobile-specific styles for About Section */
@media (max-width: 1023px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* Contact Section */
.kontaktseite {
  background-color: #f6f6f6;
  margin-left: auto;
  margin-right: auto;
}

.kontaktseite h2 {
  font-size: 2rem;
  color: black;
  border-bottom: 3px solid #ff6f61;
  padding-bottom: 10px;
  margin-bottom: 20px;
  width: 100%;
}


/* Mobile-specific styles for cooperation section */
@media (max-width: 1023px) {
  .isolation {
    background-color: #43a7ff;
    color: white;
    font-weight: bold;
    margin-bottom: 30px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .direktkontakt p {
    text-decoration: none;
  }
}

@media (min-width: 1024px) {
  .kontakt-text {
    font-size: 1rem;
    color: #af5353;
  }

  .isolation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .phone-link-about {
    color: #004b8c;
    font-weight: bold;
    text-decoration: none;
  }
  .standort, .direktkontakt {
    flex: 1;
    margin-right: 20px;
  }
}



.phone-link-about:hover {
  text-decoration: underline;
}

.map {
  margin-top: 20px;
}

.map h2 {
  font-size: 1.5rem;
  color: #004b8c;
  margin-bottom: 10px;
}

.kooperationen {
  background-color: #f6f6f6;
  margin-left: auto;
  margin-right: auto;
}

/* Cooperation and Suppliers Section */
.kooperationen-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
}

.kooperationen-item {
  flex: 1 1 calc(25% - 20px); /* Responsive width calculation */
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.kooperationen-item img {
  max-width: 100%;
  height: auto;
  max-height: 100px;
  margin-bottom: 10px;
}

.kooperationen-item:last-child {
  max-width: calc(100% - 40px); /* Ensure the last item takes full width minus the padding */
}

.kooperationen-item:last-child img {
  max-height: 100px;
  object-fit: contain;
}

.kooperationen-item a {
  text-decoration: none;
  color: #004b8c;
  font-weight: bold;
  display: block;
  margin-top: auto;
}

.kooperationen-item:hover {
  transform: translateY(-5px);
}

/* Mobile-specific styles for cooperation section */
@media (max-width: 1023px) {
  .kooperationen-container {
    flex-direction: column;
    align-items: center;
  }

  .kooperationen-item {
    flex: 1 1 100%;
    width: 80%;
  }

  .kooperationen-item:last-child {
    max-width: 100%;
  }
  .content, .header, .kooperationen, .kontaktseite {
    max-width: 100%;
    overflow-x: hidden; /* Verhindert horizontales Scrollen */
  }
  .isolation p {
    color: white;
  }

  .linkedin-link {
    color: white;
  }

}
