.footer {
  padding: 20px 40px;
  background-color: #43a7ff;
  color: white;
  text-align: center;
  border-top: 5px solid #ff6f61;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 15px rgba(0,0,0,0.1);
  margin-left: auto; /* Zentriert den Content */
  margin-right: auto; /* Zentriert den Content */
}

.footer-content p {
  margin: 0;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.footer-socials {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-icon {
  color: #fff;
  font-size: 20px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #ff6f61;
}

@media (min-width: 768px) {
  .footer {
    justify-content: space-between;
  }
  .footer-content p,
  .footer-socials {
    margin-top: 0;
  }
}