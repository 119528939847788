.header {
  background-color: #43a7ff;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
  position: fixed;
  top: 30px; /* Anpassung für die Höhe der Adressleiste */
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px; /* Reduzierte Polsterung für mobile Ansicht */
  max-width: 100%;
}

.hamburger-menu {
  font-size: 30px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: none; /* Standardmäßig für Desktop versteckt */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
}

@keyframes rotateClose {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.close-menu.rotate {
  animation: rotateClose 0.3s forwards;
}

.hamburger-menu.spin {
  animation: spin 0.3s forwards;
}

.hamburger-menu.spin-shrink {
  animation-name: none;
}

.close-menu {
  font-size: 50px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 20px;
}

.mobile-menu {
  position: fixed;
  top: 0;
  padding-top: 70px;
  right: -100%;
  height: 100%;
  width: 80%;
  background: #004b8c;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  transition: right 1s;

}

.mobile-menu.open {
  right: 0;
}

.mobile-menu a {
  color: #fff;
  padding: 15px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.mobile-menu-logo {
  display: none; /* Standardmäßig für Desktop versteckt */
}

.mobile-menu a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.header-logo {
  font-size: 24px;
  font-weight: 700;
  border-left: 5px solid #ff6f61;
  padding-left: 20px;
  margin-top: 10px;
}

.logotext {
  font-size: 40px;
  margin: 0;
  font-weight: 700;
  white-space: nowrap; 
}

@media (max-width: 1023px) {
  .logotext {
    font-size: 30px;
    white-space: normal;
  }
}

.header nav ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.header nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.header nav a::before {

}

.header nav a:hover::before {
  width: 100%;
}

.adress-bar {
  background-color: #2e2e2e;
  padding: 5px 40px;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1010;
}

.adress-text {
  color: #fff;
  font-size: 16px;
  text-align: right;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}

.adress-text a {
  color: #fff;
}

.adress-text a:hover {
  text-decoration: underline;
}

.opening-hours {
  display: inline;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.desktop-nav ul {
  list-style: none;
  display: flex;
  justify-content: right;
  padding: 0;
  
}

.desktop-nav li {
  padding: 10px;
}

.desktop-nav a {
  text-decoration: none;
  color: white;
}

.desktop-nav a:hover {
  text-decoration: underline;
}

@media (max-width: 1023px) {
  .header nav ul {
    flex-direction: column;
  }

  .header nav ul li {
    padding: 10px 0; /* Fügt vertikale Polsterung hinzu, entfernt horizontale */
  }

  .header nav a {
    padding: 10px; /* Stellt sicher, dass Links richtig gepolstert sind */
  }

  .header {
    top: 15px;
    padding: 20px 20px;
  }

  .desktop-nav {
    display: none;
  }

  .hamburger-menu {
    display: flex; /* Wird nur auf Mobilgeräten angezeigt */
  }

  .mobile-menu-logo {
    display: flex; /* Wird nur auf Mobilgeräten angezeigt */
    justify-content: center;
    margin-bottom: 20px;
  }

  .mobile-menu-logo img {
    max-width: 100%;
    height: auto;
  }

  .logotext {
    font-size: 30px;
  }

  .header-logo {
    margin: 0;
  }

  .header-content {
    justify-content: space-between;
  }

  .adress-text {
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .adress-text a {
    font-size: 10px;
    color: #fff;
  }

  .adress-text .phone-link {
    animation: pulseAnimation 2s infinite;
    text-decoration: none;
    color: white;
  }

  @keyframes pulseAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  .opening-hours {
    display: none;
  }
}

@media (min-width: 1024px) {
  .desktop-nav {
    justify-content: flex-end;
    padding-right: 100px;
  }

  .desktop-nav ul li {
    font-size: large;
  }

  .header-logo, .desktop-nav {
    flex: 1;
  }

  .header-logo {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .header nav a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #ff6f61;
    transition: width 0.3s;
  }

  .header nav a:hover::before {
    width: 100%;
  }
}

.header nav a:hover, .header nav a:active {
  text-decoration: none !important;
  border: none !important;
  box-shadow: none !important;
}

